@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/static/Montserrat-SemiBold.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-style: normal;
}

.add-holiday-page-body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

.holiday-application-container {
  width: 100%;
  max-width: 1800px;
  min-width:500px;
  height: 80vh;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  margin: auto;
}

.holiday-application-form {
  background: #FFFFFF;
  padding: 50px;
  width: 100%;
  max-width: 1200px;
  min-width:500px;
  border-radius: 20px;
  box-shadow: 4px 7px 21px 1px #00000033;
}

.holiday-application-form label {
  display: block;
  color: #242D67;
  margin-left:20px;
  font-family: 'Montserrat';
  font-size: 17px;
  font-weight:500;
  line-height: 20.72px;
}

.holiday-application-form input,
.holiday-application-form select,
.holiday-application-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #E4E4E7;
  border-radius: 9px;
  box-sizing: border-box;
  background: none;
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
}

.holiday-application-form button {
  background-color: #493971;
  color: #fff;
  padding: 12px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  margin-right: 5%;
  margin-left: 1%;
}

.input-placeholder {
  opacity: 50%;
}

.holiday-application-form button:hover {
  background-color: #6a5acd;
}
.form-container {
  text-align: center;
  color: #000000;
}

form .error-message{
  color: red;
  font-size:14px;
  text-align:left !important;
  margin-left:10px
}
.response-message{
color: green;
}

.country-radio {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
}

.country-radio label {
  margin-right: 20px;
}

.country-radio input[type="radio"] {
  margin-right: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table th,
table td {
  padding: 8px;
  text-align: center;
  font-family: Montserrat, sans-serif;
}

table th {
  background-color: #f2f2f2;
  color: #242D67;
  padding:15px;
}

table tbody tr:hover {
  background-color: #ddd;
}

@media screen and (min-width: 601px) and (max-width: 1200px) {
  .holiday-application-container {
   width: 100%;
  }
}

@media screen and (min-width: 451px) and (max-width: 600px) {
  .holiday-application-container {
   width: 100%;
  }
}
.checkbox-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 15px;
  margin-right: 10px;
}

.checkbox-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 400;
  position: relative;
  line-height: 18px;
  margin-left: 30px;
  width:130px;
}

.checkbox-item input[type="checkbox"] {
  position: absolute;
  left: 0;
  margin: 0;
  width: 20px;
  height: 20px;
  appearance: none;
  border: 2px solid #0062FF;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  transition: background 0.3s, border 0.3s;
}

.checkbox-item input[type="checkbox"]:checked {
  background: linear-gradient(90deg, #0062FF 0%, #65D1FF 100%);
  border: none;
  box-shadow: 0px 0px 0px 2px #F0F6FF;
}

.checkbox-item input[type="checkbox"]:checked::after {
  content: "";
  position: absolute;
  left: 4px;
  top: 50%;
  width: 15px;
  height: 15px;
  background-image: url('/assets/images/check.png');
  background-size: cover;
  transform: translateY(-50%);
}

.checkbox-item label {
  color: #333;
  font-family:"Roboto";
  font-size: 16px;
  font-weight: 400;
  user-select: none;
  margin-left: 30px;
}

@media screen and (max-width: 768px) {
  .holiday-application-container {
    width: 100%;
    padding: 10px;
  }

  .holiday-application-form {
    padding: 40px;
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .holiday-application-form {
    padding: 30px;
    width: 100%;
  }
}

.holiday-type-dropdown {
  color: #000; /* Set the text color to black */
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
}

.holiday-type-dropdown option {
  color: #000; /* Ensure dropdown options are black */
}