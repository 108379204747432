/* Custom styles for the pie chart container */
.custom-pie-chart-container {
    width: 100%;
    max-width: 680px;
    height: auto;
    border-radius: 20px;
    margin-top: 1.8rem;
    margin-left: auto;
    margin-right: auto;
    background: #ffffff;
    padding: 20px;
    box-shadow: 4px 7px 21px 1px #00000033;
    margin: 3.8rem auto;
    display: flex;
    justify-content: left;
    align-items: center;
    text-align: left;
}


/* Custom styles for the pie chart card */
.custom-pie-chart-card {
	width: 90%;
	height: 350px;
	background-color: #ffffff;
	border-radius: 20px;

}

/* Custom styles for the PieChart component */
.custom-pie-chart {
	max-width: 100%;
	/* Optionally, set a max-width to limit the size on larger screens */
	margin-top: -50px;
	/* Adjust margin-top as needed */
}


/* Custom styles for the Tooltip component */
.custom-pie-chart .recharts-tooltip-wrapper {
	font-family: Arial, sans-serif;
	font-size: 12px;
	font-weight: bold;
	background: linear-gradient(45deg, #FF6347, #66CDAA);
	border: none;
	border-radius: 20px;
	text-align: center;
}

.custom-pie-chart .recharts-tooltip-label {
	color: #fff;
}

/* Custom styles for the Legend component */
.custom-pie-chart .recharts-legend-wrapper {
	text-align: right;
}

.custom-pie-chart .recharts-legend-item {
	color: #242D67;
}

.chart-row {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.chart-container {
  width: 100%;
  height: auto;
  position: relative;
}
.chart-container canvas {
  width: 300px;
  height: 300px;
}

.chart-label {
	font-size: 25px;
	font-weight: bold;
	text-align: center;
	color: #242D67;
}

/* Media query for smaller screens */
@media only screen and (max-width: 768px) {
	.custom-pie-chart .recharts-legend-wrapper {
		text-align: center;
		/* Center legends horizontally */
		margin-top: 5px;
		/* Add margin to separate legends from the chart */
	}

	.custom-pie-chart .recharts-legend-item {
		display: block;
		margin-bottom: 5px;
	}

	.custom-pie-chart-container {
		padding: 15px;
	}

	.custom-pie-chart-card {
		max-width: 100%;
		height: auto;
	}

	.custom-pie-chart {
		max-width: 330%;
		/* Adjust the percentage value as needed for smaller screens */
		height: auto;
		/* Ensure the height adjusts based on content */
	}
}

@media only screen and (max-width: 480px) {
	.custom-pie-chart-container {
		padding: 10px;
		/* Further reduce padding */
	}

	.custom-pie-chart-card {
		font-size: 0.8rem;
		/* Adjust font size for smaller screens (optional) */
	}
}

.piechart-container {
  top: 309px;
  margin-top :70px;
  width: auto;
  min-width: 90%;
  margin: 3.8rem auto;
  display: inline-block;
}

@media (max-width: 767px) {
  .piechart-container,.pie-chart-container {
    margin-top: 1.5rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .piechart-container {
    padding-right:15px;
    margin-top: 1.5rem;
  }
}
.custom-legend {
  list-style-type: none;
  padding: 0;
}

.custom-legend-item {
  color: #242d67;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.custom-legend-icon {
  width: 9px;
  height: 9px;
  margin-right: 8px;
}

.custom-legend-value.inactive {
  opacity: 0.5;
}

.custom-card-body {
  padding: 1.25rem;
}

.custom-card-title {
  font-size: 1.50rem;
  color: #242d67;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.leave-detailsReport {
  margin: 6rem auto;
  width: 100%;
  gap:0px !important;
}

.leave-detailsReport table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;
}

.leave-detailsReport table th,
.leave-details table td {
  padding: 12px 15px;
  text-align: center;
  vertical-align: middle;
}

.leave-detailsReport table thead th:first-child {
  border-top-left-radius: 12px;
}

.leave-detailsReport table thead th:last-child {
  border-top-right-radius: 12px;
}

.leave-detailsReport table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 12px;
}

.leave-detailsReport table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 12px;
}

.leave-detailsReport table th {
  background-color: #242D67;
  color: #fff;
  font-weight: bold;
}

.leave-detailsReport table td {
  background-color: #f9f9f9;
  color: #333;
}

@media (max-width: 768px) {
  .leave-details {
    width: 100%;
  }

  .leave-detailsReport table th,
  .leave-detailsReport table td {
    padding: 8px 10px;
  }
}

@media (max-width: 480px) {
  .leave-detailsReport table th,
  .leave-detailsReport table td {
    padding: 5px;
  }
}

.pie-chart-container {
  position: relative;
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -15px;
}

.outer-circle {
  position: absolute;
  width: 90%;
  height: 90%;
  border-radius: 50%;
  background-color: #242D67;
  clip-path: circle(50% at 50% 50%);
}

.inner-circle {
  position: absolute;
  width: 50%;
  height: 50%;
  border-radius: 50%;
  background-color: #D3BDFF;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
}
.lop-tooltip {
  position: absolute;
  width: 40%;
  height: 10%;
  top: 35%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 4px;
  border-radius: 5px;
  z-index: 10;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
}

.small-circle {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #D3BDFF;
  margin-right: 5px;
}

.lop-legend {
  position: absolute;
  top: 94%;
  transform: translate(-100%);
  display: flex;
  align-items: center;
  margin-top: 10px;
  color: #808080;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
}

.chart-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 20px 20px 20px 20px;
  opacity: 1px;
  box-shadow: 1px 3px 10px 1px #00000033;
  padding:20px;
}

.chart-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.chart-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 150px;
  margin: 10px;
  flex-grow: 1;
}

.no-data-message {
  font-size: 23px;
  padding-left :500px;
}

.custom-pie-chart-container svg:focus,
.custom-pie-chart-container path:focus,
.custom-pie-chart-container circle:focus {
  outline: none;
}

.custom-pie-chart-container svg:focus-visible,
.custom-pie-chart-container path:focus-visible,
.custom-pie-chart-container circle:focus-visible {
  outline: none;
}

.loading-container-pieChartData {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh;
  width: 100%;
  position: absolute;
  top: 150px;
  left: 0;
  background-image: url('/assets/images/Loading.gif');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1000;
}

