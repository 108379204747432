.application-container {
  width: 100%;
  height: 100vh;
  background-size: cover;
  margin-top:5%;
}

.wfh-application-form label {
  display: block;
  color: #242D67;
  margin-bottom: 0px;
  height: 70px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 17px;
}

.wfh-application-form input{
  width: auto;
  padding: 8px;
  margin-bottom: 12px;
  border: 2px solid #E4E4E7;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 14.63px;
  text-align: left;
  color: black;
}

.wfh-application-form textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 12px;
  border: 2px solid #E4E4E7;
  border-radius: 9px;
  box-sizing: border-box;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 14.63px;
  text-align: left;
  color: black;
  height: 100%;
}

.input-placeholder {
  opacity: 50%;
}

.wfh-application-form select {
  width: 100%;
  height: 100%;
  padding: 8px;
  margin-top: 8px;
  border: none;
  border-radius: 9px;
  font-size:14px !important;
  background-color: #FFFFFF;
  border: 2px solid #E4E4E7;
}

.form-container {
  text-align: center;
  color: #6a5acd;
  max-width: 55%;
  margin: 0 auto;
}

.error-message{
  color: red;
  font-size:14px;
  text-align:left;
  margin-top: 30px;
}

.leave-application-forms{
  display: flex;
  justify-content: space-between;
}
.column {
  flex: 1;
  margin-right: 20px;
}

@media only screen and (max-width: 767px) {
  .leave-application-form, .wfh-application-form {
   flex-direction: column;
  }

}
.form-row {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: 33px;
  width: auto;
  height: auto;
}

.form-group {
  flex: 1;
  min-width: 200px;
  margin-right: 10px;
  padding: 5px;
}

.date-group {
  display: flex;
  gap: 20px;
}

.date-group label {
  display: block;
  font-weight: bold;
}

input[type="date"],
select,
input[type="number"],
textarea {
  width: 100%;
  height: 100%;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 9px;
}

.wfh-application-form {
  background: #FFFFFF;
  padding: 30px;
  box-shadow: 4px 7px 21px 1px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  border: 3px solid transparent;
  width: auto;
  min-width: 500px;
  margin: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}
.success-message {
  color: green;
  margin-top: 10px;
}
.error-message {
  color: red;
}
.wfh-application-form .button-container {
   display: flex;
   justify-content: space-between;
   margin-top: 20px;
}

.wfh-application-form button {
  background-color: #242D67;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 3px 10.5px 0px #00000026;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  gap: 10px;
  width: auto;
  height: auto;
}

.apply-response {
  margin-top: 10px;
}

.apply-response.success {
  color: green;
}

.apply-response.error {
  color: red;
}