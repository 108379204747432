.right-content-emp {
  padding: 34px;
}

.userpage-container-emp {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
}

.allusers-table-emp {
  width: 100%;
  min-width: 5%;
  border-collapse: separate;
  z-index: 0;
  border-radius: 15px;
  border-spacing: 0;
  overflow: hidden;
}

.right-align-emp {
  text-align: right;
  cursor: pointer;
}

.left-align-emp {
  text-align: left;
  cursor: pointer;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-left: 1px solid #EAECF0;
  border-right: 1px solid #EAECF0;
}

table th,
table td {
  border-top: 1px solid #EAECF0;
  border-bottom: 1px solid #EAECF0;
  padding: 8px;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 500;
}

table th {
  background-color: #f0f6ff;
  color: #667085;
  padding:15px;
}

.reject-button-emp {
  background-color: #f94141;
  color: white;
  border: 2px solid;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 18px;

}

.approve-button-emp {
  background-color: #49e945;
  color: white;
  border: 2px solid;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 18px;
}

.button-container-emp {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.approve-button-emp:hover {
  background-color: #008000;
  color: white;
}

.reject-button-emp:hover {
  background-color: #cc0000;
  color: white;
}

.user-profile-photo-emp {
  border-radius: 50%;
  width: 50px;
  height: 45px;
  margin-left: auto;
  margin-right: auto;
}

.pagination-active-user-emp{
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 30px;
  width:100%;
}

.pagination-active-user-emp li a {
  color: #667085;
  text-decoration: none;
  cursor: pointer;
  font-family: Inter, sans-serif;
  font-size: 14px;
}

.pagination-active-user-emp li.active a {
  background-color: #F9F5FF;
  border-radius: 5px;
  padding: 12px 16px;
}

.pagination-active-user-emp > li:first-child {
  margin-right: auto;
}

.pagination-active-user-emp > li:last-child {
  margin-left: auto;
}

.pagination-active-user-emp > li:not(:first-child):not(:last-child) {
  display: inline-block;
  margin: 0 10px;
}

.disabled-button-emp {
  pointer-events: none;
  opacity: 0.5;
}

.email {
  color: #667085;
  font-family: Inter, sans-serif;
  font-size: 14px;
}

.icon-left {
  margin-right: 5px;
  margin-bottom: -1px;
  margin-left: -28px;
}

.icon-right {
  margin-left: 5px;
  margin-bottom: -1px;
  margin-right: -20px
}

.icon-text {
  margin: 0 4px;
}

