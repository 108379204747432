.leave-details-container {
  text-align: center;
}

.leave-table {
  margin: 0 auto;
  border-collapse: collapse;
  width: 100%;
  margin-top: 15px
}

.leave-table th, .leave-table td {
  border: 1px solid #ddd;
  padding: 8px
}

.leave-table th {
  background-color:#f2f2f2
}

.leave-year {
  margin-top: 10px;
  color: white;
  background-color: #493971;
}

.download-button {
  background-color: #222d64;
  color: white;
  padding: 6px 10px;
  font-size: 16px;
  width: auto;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  text-align: center;
}

.leaverequest-download-button, .revert-leave-button {
  background-color: #222d64;
  color: white;
  padding: 6px 10px;
  font-size: 16px;
  width: auto;
  border: 1px solid #ddd;
}

.leave-request-table {
  width: 100%;
  min-width: 5%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 15px;
  overflow: hidden;
  z-index: 0;
  margin-top:50px;
}

.leave-request-table th {
  background-color: #f0f6ff;
  color: #191919;
  padding:15px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.6px;
  text-align: center;
}

.leave-request-table td {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.76px;
  color: #4C4C4C;
}

.leave-request-table tbody tr:nth-child(even) {
  background-color: #FAFCFF;
}

.leaverequest-upload-button {
  background-color: #222d64;
  color: white;
  padding: 6px 10px;
  font-size: 16px;
  width: auto;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
}

.file-error-message {
  color: red;
}

.upload-success-message {
  color: green;
}

.upload-error-message {
  color: red;
}