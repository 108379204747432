.swapped-holiday-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  padding: 34px;
}

.swapped-holiday-table {
  width: 100%;
  min-width: 5%;
  border-collapse: separate;
  z-index: 0;
  border-radius: 15px;
  border-spacing: 0;
  overflow: hidden;
  border-left: 1px solid #EAECF0;
  border-right: 1px solid #EAECF0;
}

.swapped-holiday-table th,
.swapped-holiday-table td {
  border-top: 1px solid #EAECF0;
  border-bottom: 1px solid #EAECF0;
  padding: 8px;
  text-align: left;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.swapped-holiday-table th {
  background-color: #f0f6ff;
  color: #667085;
  padding: 15px;
}

.startDate {
  color: #667085;
  font-family: Inter, sans-serif;
  font-size: 14px;
}

.no-data {
  padding: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  text-align: center !important;
}

.swapped-holiday-table tr:hover {
  background-color: #f9f9f9;
}

.swapped-holiday-table td {
  cursor: pointer;
}

.next-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: visible;
}
