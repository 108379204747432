.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35vh;
  width: 100%;
  position: absolute;
  top: 23%;
  left: 0;
  background-image: url('/assets/images/Loading.gif');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1000;
}
