@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

.search-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0px;
  margin-top: 15px;
  margin-bottom: 15px !important;
  margin-left: 15px;
  margin-right: 0;
}

.name-email-container {
  display: flex;
  align-items: center;
  gap: 0;
}

.name-element {
  color: #101828;
  font-family: 'Roboto';
  font-weight: bold;
}

.email-element {
  color: #667085;
  font-family: 'Roboto';
}

.search-container input,
.search-container select,
.search-container button {
  margin: 0;
  padding: 5px;
}

.search-container input,
.search-container select {
  background-color: #F7FAFF;
}

.search-container input{
  border: 1px solid #ccc;
  padding: 4px 8px;
  border-radius: 8px;
  margin-right:10px;
}

.search-container select {
  background-image: url('/assets/images/downarrow.png');
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) center;
  appearance: none;
  border: 1px solid #ccc;
  padding: 4px 8px;
  border-radius: 8px;
  width: 200px;
}

.search-container button {
  background-color: #242D67;
  margin-top:0px !important;
  padding: 1rem;
  background-image: url('/assets/images/search-icon.png');
  background-size: 22px 22px;
  background-repeat: no-repeat;
  background-position: center;
  width : 62px;
}

.reportspage-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;
}

.user-table-reports tr{
  background : none !important;
}

.leave-details.non-probation {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto;
  gap: 20px;
  grid-template-areas:
    "a b"
    "c b";
}

.leave-details.probation {
  display: rows;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto;
  gap: 20px;
  grid-template-areas:
    "a"
    "c";
}

.leave-details.probation .sick-leave {
  grid-area: a;
  padding-left: 17px;
}

.leave-details.non-probation .sick-leave {
  grid-area: a;
  padding-left: 17px;
}

.leave-details.non-probation .annual-leave {
  grid-area: b;
  margin-top: -76px;
}

.leave-details.non-probation .casual-leave,
.leave-details.non-probation .earned-leave {
  grid-area: c;
}

.leave-details.non-probation .LOP-leave {
  grid-area: b;
  margin-top: 76px;
  padding-left: 57px;
}

.leave-details.probation .LOP-leave {
  grid-area: a;
  padding-left: 57px;
}

.leave-type {
  display: flex;
  align-items: center;
  margin-bottom: 15px !important;
}

.leave-type span {
  margin-right: 10px !important;
  margin-left: 10px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  color : #242D67;
  font-family: 'Roboto';
  weight :500;
}

.pagination-reports {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  list-style: none;
  padding: 0;
}

.previous-button {
  margin-right: auto;
}

.next-button {
  margin-left: auto;
}

.pagination-reports li a {
  padding: 5px 10px;
  text-decoration: none;
  color: #667085 !important;
  border : none !important;
}

.pagination-reports li.active a {
  background-color: #F9F5FF;
  color: black !important;
  border-radius: 3px;
}

.disabled-button {
  background: none !important;
}

.pagination-reports li.disabled-button a {
  color: #ddd;
  pointer-events: none;
}

.user-table-reports {
  width: 100%;
  border-collapse: collapse;
}

.user-table-reports th,
.user-table-reports td {
  padding: 10px;
  vertical-align: middle;
  background-color: transparent;
  border-left: none;
  border-right: none;
}

.user-table-reports th {
  text-align: left;
  background: #F0F6FF;
}

.user-table-reports td {
  border-right: 1px solid #ddd;
}

.user-table-reports th:last-child,
.user-table-reports td:last-child {
  border-right: none;
}

.user-table-reports th:first-child,
.user-table-reports td:first-child {
  border-right: none;
}
.user-table-reports td.name-column {
  text-align: left;
}

.employee-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.employee-info img {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin-right: 10px !important;
}

.employee-info div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 80%;
  height: 6px;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
  border-radius: 5px;
  border: none;
  margin-left: 30px !important;
  margin-top: 18px !important;
  padding: 0 !important;
  position: relative;
  background: linear-gradient(to right, #0062FF var(--thumb-position), #65D1FF var(--thumb-position), #E5EFFF var(--thumb-position), #E5EFFF 100%);
}

input[type="range"]:hover {
  opacity: 1;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: transparent;
  border-radius: 5px;
  border: none;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: transparent;
  border-radius: 5px;
  border: none;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: transparent;
  border-radius: 5px;
  border: none;
  color: transparent;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 22px;
  height: 22px;
  background:  linear-gradient(to right, #0062FF 0%, #65D1FF);
  border-radius: 50%;
  border: 4px solid #E0E1E9;
  margin-top: -7px;
  cursor: pointer;
  position: relative;
}

input[type="range"]::-moz-range-thumb {
  width: 12px;
  height: 12px;
  background:  linear-gradient(to right, #0062FF 0%, #65D1FF);
  border-radius: 50%;
  border: 4px solid #E0E1E9;
  cursor: pointer;
  position: relative;
}

input[type="range"]::-ms-thumb {
  width: 20px;
  height: 20px;
  background:  linear-gradient(to right, #0062FF 0%, #65D1FF);
  border-radius: 50%;
  border: 4px solid #E0E1E9;
  margin-top: -7px;
  cursor: pointer;
  position: relative;
}

input[type="range"]:active::-webkit-slider-thumb {
  background: #0056b3;
}

input[type="range"]:active::-moz-range-thumb {
  background: #0056b3;
}

input[type="range"]:active::-ms-thumb {
  background: #0056b3;
}
.profile-photo {
 display : flex;
 align-items: left !important;
}

.range-input-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 1;
  white-space: nowrap;
}

.range-input-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.tooltip-top {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 5px;
}

