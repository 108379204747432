.download-salaryslip-container {
  text-align: center;
}

.salaryslip-table {
  margin: 0 auto;
  border-collapse: collapse;
  width: 100%;
  float: right;
}

.salaryslip-table th, .salaryslip-table td {
  border: 1px solid #ddd;
  padding: 8px
}

.salaryslip-table th {
  background-color: #f2f2f2
}

.approval-salaryslip-container {
  text-align: center;
  width: 254vh;
  height: 100vh;
}

.approval-salaryslip-table {
  margin: 0 auto;
  border-collapse: collapse;
  width: 100%;
  float: right;
}