.leave-popup-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.leave-popup-title {
  font-size: 1.2em;
  margin-bottom: 15px;
}

.leave-popup-label {
  display: block;
  margin-bottom: 10px;
}

.leave-popup-input,
.leave-popup-select {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
}

.leave-popup-buttons {
  margin-top: 20px;
  text-align: right;
}

.leave-popup-submit,
.leave-popup-close {
  padding: 10px 20px;
  margin-left: 10px;
  cursor: pointer;
  border: none;
  border-radius: 3px;
}

.leave-popup-submit {
  background-color: #4caf50;
  color: #fff;
}

.leave-popup-close {
  background-color: #f44336;
  color: #fff;
}

/* style for approve and reject button */

.reject-button {
  background-color: #f94141;
  color: white;
  border: 2px solid;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.approve-button {
  background-color: #49e945;
  color: white;
  border: 2px solid;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.approve-button:hover {
  background-color: #008000;
  color: white;
}

.reject-button:hover {
  background-color: #cc0000;
  color: white;
}

/* style for input field in pop */

.input-style{
    border: 2px solid #3498db;
    padding: 8px;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s ease-in-out
}

.input-style:focus{
    border-color: #2ecc71;
}
.leave-popup-content {
  width: auto;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.leave-popup-error-message {
  color: red !important;
  font-size: 14px;
  text-align: left !important;
  margin-left: 10px;
}
