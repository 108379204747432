/* login.css */

.login-container {
  text-align: center;
  display: flex;
  min-height: 100vh;
  margin: 0;
  align-items: center;
  justify-content: center;
  color: white;
  width: 100%;
 background-image: linear-gradient(79deg, #232c6b, #232c6b 55%, #765DF7);
  padding: 0 29rem;
  overflow: hidden;
}

.login-form-container,
.login-form,
.register-form {
  display: flex;
  flex-direction: column;
}


.login-label {
  text-align: left;
  padding: 0.25rem 0;
}

.login-input {
  margin: 0.5rem 0;
  padding: 1rem;
  border: none;
  border-radius: 10px;
}

.login-button {
  border: none;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  color: #7439db;
}

.login-link-btn {
  background: none;
  color: white;
  text-decoration: underline;
}



.login-image-container {
  flex: 1;
  padding: 15rem;
  background-image: url('/assets/images/atmini.jpeg');
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  max-width:10%;
}

@media screen and (max-width: 350px) {
  .login-container {
    display: flex;
    padding: 0 10rem;
    min-height: 160vh;
    flex-direction: column;
    justify-content: flex-start;
  }
.login-form-container {
  padding: 5rem 1rem 7rem 15rem;
  width: 110%;
}
  .login-image-container {
    padding: 18rem 11rem 9rem 8rem;
    max-height: 0vh;
    margin-left: auto;
  }
}

@media screen and (min-width: 351px) and (max-width: 768px) {
  .login-container {
    padding: 0 14rem;
    height: 150vh;
  }
  .login-form-container {
    padding: 1rem;
  }
  .login-image-container {
    padding: 10rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .login-container {
    padding: 0 10rem;
    height: 500%;
    width: 300%;
  }
  .login-form-container {
    padding: 1rem;
  }
}

@media screen and (min-width: 600px) {
  .login-form-container {
    padding: 5rem;
    border: 1px solid white;
    border-radius: 10px;
  }
}

.login-box div {
  position: absolute;
  width: 60px;
  height: 60px;
  background-color: transparent;
  border: 6px solid rgba(255, 255, 255, 0.1);
}

.login-box div:nth-child(1) {
  top: 12%;
  left: 42%;
  animation: animate 10s linear infinite;
}

.login-box div:nth-child(2) {
  top: 70%;
  left: 50%;
  animation: animate 7s linear infinite;
}

.login-box div:nth-child(3) {
  top: 17%;
  left: 6%;
  animation: animate 9s linear infinite;
}

.login-box div:nth-child(4) {
  top: 20%;
  left: 60%;
  animation: animate 10s linear infinite;
}

.login-box div:nth-child(5) {
  top: 67%;
  left: 10%;
  animation: animate 6s linear infinite;
}

.login-box div:nth-child(6) {
  top: 80%;
  left: 70%;
  animation: animate 12s linear infinite;
}

.login-box div:nth-child(7) {
  top: 60%;
  left: 80%;
  animation: animate 15s linear infinite;
}

.login-box div:nth-child(8) {
  top: 32%;
  left: 25%;
  animation: animate 16s linear infinite;
}

.login-box div:nth-child(9) {
  top: 90%;
  left: 25%;
  animation: animate 9s linear infinite;
}

.login-box div:nth-child(10) {
  top: 20%;
  left: 80%;
  animation: animate 5s linear infinite;
}

@keyframes animate {
  0% {
    transform: scale(0) translateY(0) rotate(0);
    opacity: 1;
  }
  100% {
    transform: scale(1.3) translateY(-90px) rotate(360deg);
    opacity: 1;
  }
}

.login-signup-icon {
  position: fixed;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #ffffff;
}

.login-password-toggle-icon {
  margin-left: 200px;
  position: relative;
  top: -36px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #6c757d;
}