.birthday-container {
  font-family: Arial, sans-serif;
  background: #FFFFFF;
  padding: 20px;
  box-shadow: 4px 7px 21px 1px #00000033;
  border-radius: 20px;
  text-align: left;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.birthday-container h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #242D67;
}

.custom-birthday-container {
    text-align: left;
    width: 100%;
    max-width: 680px;
    height: auto;
    margin: 3.8rem auto;
    display: flex;
    flex-direction: column;
    align-items: left;
}