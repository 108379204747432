@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

.add-employee-body {
  margin: 0;
  width: 100%;
  padding: 0;
  font-family: Roboto, -apple-system, "Helvetica Neue", "Segoe UI", Arial, sans-serif;
  background: white;
/*  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;*/
  }

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto !important;
  width: 1100px !important;
  margin-right:-10px;
  overflow-y:auto !important;
}

.forms-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
}

.form-button {
  background-color: #222d64;
  color: #fff;
  margin-top: 20px;
  margin-bottom: 100px;
  width: 90px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.address-title {
  color: #222d64;
  font-family: Montserrat;
  font-size: 17px;
  font-weight: 700;
}

.address-section {
  border-radius: 20px ;
  box-shadow: 2px 3px 4px 1px #00000033;
  margin-top:0px;
  margin-bottom:20px;
  padding-left:40px;
  padding-bottom:20px;
  margin-right: 40px;
}

.custom-spacing {
  margin-right:-10px;
}

.input-block {
}

.AllManager {
  width:17px !important;
  height: 17px !important;
  margin-left : -240px !important;
}

.AllManagers {
  margin-left : 5px;
}

.input-block label:not(.custom-select-option label)  {
  width: 261px;
  height: 19px;
  margin-top:20px;
  font-family: Roboto;
  font-size: 14px !important;
  font-weight: 500;
  line-height: 10.97px;
  color: #000000;
}

.input-block input {
  display: block;
  border: 2px solid #666;
  border-radius: 5px;
  width: 231px;
  height: 39px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  color: #000000;
}

.input-block select {
  padding:10px;
  display: block;
  width: 231px;
  height: 37px;
  background:none !important;
  margin-top:8px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  color: #000000;
  border: 2px solid #666;
  border-radius: 5px;
}

.input-block input[type="date"] {
  display: block !important;
  width: 231px ;
  height: 36px !important;
  background:none !important;
  font-family: Roboto !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #000000 !important;
  padding: 5px 10px !important;
  box-sizing: border-box !important;
}

.form-wrapper.is-active .form [type="submit"] {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.4s ease-in;
}

.user-icon {
  width: 75px;
  height: 75px;
  background-size: cover;
  background-position: center;
}

.file-input {
  display: none;
}

.input-placeholder {
  opacity: 50%;
}

.errors-message {
  color: red;
  font-size:14px;
  text-align: left !important;
  margin-top:10px;
}

form .error-messages{
  color: red;
  font-size:17px;
  text-align: center !important;
  margin-top:20px;
}

form .success-message{
  color: green;
  font-size:17px;
  text-align: center !important;
  margin-top:20px;
}

.multi-select-container {
  position: relative;
  display: inline-block;
}

.multi-select-label{
  margin-bottom:15px;
}

.custom-select-container {
  position: relative;
  width: 200px;
}

.custom-select-header {
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background:none !important;
  width: 231px;
  height: 36px;
  text-align:left;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  border: 2px solid #666;
  border-radius: 5px;
  margin-top:-5px;
  margin-right:10px;
}

.custom-select-text-wrapper {
  flex-grow: 1;
  overflow-x: auto;
  white-space: nowrap;
}

.custom-select-text {
  white-space: nowrap;
  overflow: hidden;
}

.custom-select-arrow {
  margin-left: 10px;
}

.custom-select-dropdown {
  position: absolute;
  border: 1px solid #ccc;
  border-top: none;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background: white;
  z-index: 1;
  border-radius:10px;
}

.custom-select-option {
  padding-left: 10px;
  display: flex;
  align-items: center;
  border-radius:20px;
}

.custom-select-option input {
  margin: 0;
  margin-right: 10px;
  width: 14px;
  height: 14px;
}

.custom-select-option label {
  margin: 0;
  padding-left: 5px;
  display: inline-block;
  font-size: 14px;
}

@media screen and (min-width: 1310px) {
    :root {
        --form-width: 85vw;
    }
    .form {
        width: var(--form-width) !important;
        margin-left: 2.5vw !important;
        margin-right: 50px !important;
    }

    .form form-login * {
        width: auto !important;
    }

    .input-block label:not(.custom-select-option label) {
        width: calc((100vw - var(--form-width)) * 1.3);
    }

    .input-block input:not(.custom-select-option input),
    .input-block select,
    .input-block input[type="date"] {
        width: calc((100vw - var(--form-width)) * 1.1);
    }
    .custom-select-container {
        width: calc((100vw - var(--form-width)) * 1.1);
    }

    .custom-select-header {
        width: calc((100vw - var(--form-width)) * 1.1);
    }

    .custom-select-dropdown {
        width: 100% !important;
    }
    .custom-select-option {
        margin-right: 50px;
    }
}

@media screen and (max-width: 1309px) {
    :root {
        --form-width: 85vw;
    }
    .form {
        width: var(--form-width) !important;
        margin-left: 2.5vw !important;
        margin-right: 50px !important;
    }

    .form form-login * {
        width: auto !important;
    }
    .input-block label:not(.custom-select-option label)  {
        width: calc((100vw - var(--form-width)) * 1.3);
    }

    .input-block input:not(.custom-select-option input),
    .input-block select,
    .input-block input[type="date"] {
        width: calc((100vw - var(--form-width)) * 1.2) !important;
    }
    .custom-select-container {
        width: calc((100vw - var(--form-width)) * 1.2);
    }

    .custom-select-header {
        width: calc((100vw - var(--form-width)) * 1.2);
    }

    .custom-select-dropdown {
        width: 100% !important;
    }
}

.search-results {
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  background: white;
  position: absolute;
  z-index: 1;
  width: 19%;
  text-align:left;
  border-radius : 8px;
  margin-top:-9px;
}

.search-item {
  padding: 10px;
  cursor: pointer;
}

.search-item:hover {
  background-color: #f0f0f0;
}

.no-managers-found {
  padding: 10px;
  color: red;
}

.dropdown-containers input{
  width : 85% !important;
}