.top-nav-bar {
  display: flex;
  align-items: center;
  background-color: #222d64;
  height: 60px;
  width: 100%;
}

.top-nav-bar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.top-navbar {
  flex-grow: 1;
  position: relative;
}

.top-navbar a {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  text-decoration: none;
  padding: 0 15px;
  height: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  white-space: nowrap;
  font-weight: bold; 
  font-family: "Montserrat", sans-serif;
}

.top-navbar.active {
  background: rgba(50, 197, 255, 0.8);;
  color: #000;
  height: 60px;
  width: 250px;
  align-items: center;
  padding: 0 15px;
  flex-grow: 1;
}

.leave-details-container {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
}

.leave-approval-container {
  width: 100%;
  height: 100vh;
  background-image: url('/assets/images/leaveapproval.png');
  background-size: cover;
}

.salary-details-container {
  width: 100%;
  height: 100vh;
  background-image: url('/assets/images/salarydetails.jpg');
  background-size: cover;
  background-position: center;
}

.WFH-approval-container {
  width: 100%;
  height: 100vh;
  background-image: url('/assets/images/wfhdetails.jpg');
  background-size: cover;
  background-position: center;
}

.notification-bell-item {
  display: flex;
  align-items: center;
}

.notification-bell-item .notification-bell-page {
  margin-left: auto;
  padding-right: 16px;
}

.upload-salaryslip-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 600px;
}

.download-salaryslip-container {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
}