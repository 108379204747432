.application-container {
  width: 100%;
  height: 100vh;
  background-size: cover;
}

.application-form {
  background-color: #f5f5fa;
  padding: 20px;
  border-radius: 8px;
  width: 684px;
  height: auto !important;
  margin: auto;
  margin-top: 100px;
  background: #FFFFFF;
  box-shadow: 4px 7px 21px 1px #00000033;
}

.form-field-container {
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
}

.form-field-container label {
  display: block;
  color: #000000;
  margin-bottom: 0px;
  height: 70px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 17px;
}

.form-field-container select{
  width: 100%;
  height:120%;
  padding: 8px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  margin-bottom: 0px;
  margin-top:-30px;
  background: #D9D9D966;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.form-field-container input{
  width: 100%;
  padding: 8px;
  margin-bottom: 12px;
  box-sizing: border-box;
  background: #D9D9D966;
  line-height: 14.63px;
  text-align: left;
  color: black;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.input-placeholder {
  opacity: 50%;
}

.uss-upload-file{
  width: 100%;
  height:100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 0px;
  margin-top:-30px;
}

.uss-datepicker {
  width: 100%;
  height:100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 0px;
  margin-top:-50px;
  background: #D9D9D966;
}

.btn-uss {
  background-color: #242D67;
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin-top: 40px;
  width: 123px;
  height: 43px;
  top: 705px;
  margin-left:250px;
}