/* Adjustments for the outline container */
.custom-container {
	font-family: Arial, sans-serif;
	background: #FFFFFF;
	padding: 20px;
	box-shadow: 4px 7px 21px 1px #00000033;
	border-radius: 20px;
	text-align: left;
	width: 100%;
	max-width: 680px;
	height: auto;
	margin: 1.8rem auto;
	display: flex;
	flex-direction: column;
	align-items: left;
}

h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    font-weight: 600; /* Updated font-weight */
    color: #242D67;
}

.react-calendar {
	width: 100%;
	border: none;
	color: #242D67;
	margin-top: 20px;
	/* Set a fixed height for the calendar */
	height: 300px;
	/* Adjust this value as needed */
	overflow-y: auto;
	/* Add vertical scroll if needed */
}

/* Change the color of the date numbers */
.react-calendar__month-view__days__day {
	color: #000000;
	background-color: transparent;
	padding: 0;
	display: inline-block;
	width: 30px;
	height: 35px;
	line-height: 10px;
	text-align: center;
	margin: 2px;
	position: relative;
}

/* Change the color of the date numbers for weekend */
.react-calendar__month-view__days__day--weekend {
	color: #ED1C24;
}

/* Change the color of the weekday names (Sun, Mon, etc.) */
.react-calendar__month-view__weekdays {
	color: #242D67;
}

/* Change the color of the month text */
.react-calendar__navigation__label__month {
	color: #242D67;
}

/* Change the color of the year text */
.react-calendar__navigation__label__year {
	color: #242D67;
}

/* Change the color of the  year and month */
.react-calendar__navigation button {
	color: transparent;
}

/* Change the background color of the selected date */
.react-calendar__tile--active {
	color: #fff;
	font-size: 12px;
	font-weight: bold;
	background: rgb(16, 135, 255);
	border: none;
	border-radius: 20px;
	text-align: center;
}

.leave-reason {
	font-size: 12px;
	color: #ED1C24;
}

.selected-date-reasons {
	margin-top: 20px;
}

/* Style for the list of reasons */
.selected-date-reasons ul {
	list-style-type: none;
	padding: 0;
}

/* Style for individual reasons in the list */
.selected-date-reasons li {
	margin-bottom: 8px;
    display: flex;
    align-items: center;
}

.custom-chart-heading {
	font-size: 1.25rem;
	color: #000;
	text-align: center;
	margin-bottom: 10px;
}

.leave-date-start {
  background-color: green;
  color: white;
}

.leave-date-end {
  background-color: red;
  color: white;
}

/* Media Query for smaller devices */
@media only screen and (max-width: 768px) {
  .custom-container {
     padding: 15px;
  }
}

/* Media Query for mobile devices */
@media only screen and (max-width: 480px) {
  .custom-container {
     padding: 10px;
  }
}

.user-home-page-container {
  display: flex;
  justify-content: space-between;
  /* Add space between calendar and pie chart */
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.calendar-container, .chart-container, .holiday-progress-container {
  flex: 1 1 45%;
  min-width: 300px;
  margin-left: 10px;
  margin-right:10px;
}

@media only screen and (max-width: 1200px){
  .user-home-page-container{
     height: auto;
     overflow-y: auto;
  }
}

@media only screen and (max-width: 1200px) {
  .user-home-page-container {
     height: auto;
     overflow-y: auto;
  }

  .calendar-container, .chart-container, .holiday-progress-container {
    flex: 1 1 100%;
    margin: 10px 0;
  }
}


.new-custom-container{
    text-align: left;
    width: 100%;
    max-width: 680px;
    height: auto;
    margin: 1.8rem auto;
    display: flex;
    flex-direction: column;
    align-items: left;
}

.reason-container {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0px 3px 10.5px 0px #00000026;
  display: flex;
  align-items: center;
}

.reason-container li {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 17.07px;
  text-align: left;
  color: #242D67;
}

.combined-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top:30px;
}

.day-date {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.day-date p {
  margin: 0;
  font-size: 16px;
  color: #242D67;
  font-weight: bold;
}

.selected-date-reasons li::before {
    content: "";
    width: 14px;
    height: 14px;
    background-color: #32C5FF;
    border-radius: 50%;
    margin-right: 20px;
    margin-left: 10px;
}

/* Hide next and previous year navigation symbols */
.react-calendar__navigation button.react-calendar__navigation__prev2-button,
.react-calendar__navigation button.react-calendar__navigation__next2-button {
    display: none !important;
}

/* Style next month button vector */
.react-calendar__navigation button.react-calendar__navigation__next-button:after {
    content: "▶"; /* Unicode arrow character */
    color: #000000;
    padding-right:25px;
}

/* Style previous month button vector */
.react-calendar__navigation button.react-calendar__navigation__prev-button:after {
    content: "◀"; /* Unicode arrow character */
    color: #000000;
    padding-left:20px;
}

/* Set styles for the month */
.react-calendar__navigation button.react-calendar__navigation__label {
    color: #000000;
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    font-weight: 600;
}

/* Remove hover effect on month label */
.react-calendar__navigation button.react-calendar__navigation__label:hover {
    background-color: transparent;
}

 /*Remove hover effect on previous and next month buttons*/
.react-calendar__navigation button.react-calendar__navigation__prev-button:hover,
.react-calendar__navigation button.react-calendar__navigation__next-button:hover {
    background-color: transparent;
}

.leave-date-tile {
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: red;
    font-size: 1rem;
    pointer-events: none;
}
