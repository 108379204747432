.App {
  text-align: center;
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  color: white;
  /*background-image: linear-gradient(79deg, #7439db, #C66FBC 48%, #F7944D);*/
}

.auth-form-container, .login-form, .register-form {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 600px) {
.auth-form-container{
padding: 5rem;
border: 1px solid white;
border-radius: 10px;
}
}

label {
  text-align: left;
  padding: 0.25rem 0;
}

input {
  margin: 0.5rem 0;
  padding: 1rem;
  border: none;
  border-radius: 10px;
}

button {
  border: none;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  color: #7439db;
}
.link-btn {
  background: none;
  color: white;
  text-decoration: underline;
}