.leave-application-container {
  width: 100%;
  height: 100vh;
  background-size: cover;
  margin-top:5%;
}

.leave-form-container {
  text-align: center;
  color: #6a5acd;
  max-width: 55%;
  margin: 0 auto;
}

.leave-error-message {
  color: red;
  font-size: 14px;
  margin-top: 50px;
  margin-bottom: 10px;
  text-align:left;
}

.leave-application-form {
  background: #FFFFFF;
  padding: 30px;
  box-shadow: 4px 7px 21px 1px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  border: 3px solid transparent;
  width: 100%;
  min-width: 500px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-bottom:30px;
}

.leave-application-form label{
  display: block;
  color: #242D67;
  margin-bottom: 20px;
  height: 45px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 17px;
}

.leave-application-form input{
  padding: 8px;
  margin-bottom: 12px;
  border: 2px solid #E4E4E7;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 14.63px;
  text-align: left;
  color: black;
}

.leave-application-form textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 12px;
  border: 2px solid #E4E4E7;
  border-radius: 9px;
  box-sizing: border-box;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 14.63px;
  text-align: left;
  color: black;
  height: 55px;
  margin-top: 3px;
}

.leave-application-form select {
  width: 100%;
  height: 55px;
  padding: 8px;
  margin-top: 8px;
  border: none;
  border-radius: 9px;
  font-size:14px !important;
  background-color: #FFFFFF;
  border: 2px solid #E4E4E7;
}

.leave-application-form button {
  background-color: #242D67;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 3px 10.5px 0px #00000026;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.leave-application-form .button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.leave-application-forms{
  display: flex;
  justify-content: space-between;
}

.leave-application-container input[type="date"] {
  width: 100%;
  height: 55px;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 9px;
}

.leave-application-container input[type="number"] {
  width: 104%;
  height: 55PX;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 9px;
  margin-top: 6px;
  border: 2px solid #E4E4E7;
  margin-bottom:20px !important;
}

.leave-submit-button {
  width: 100%;
  background: #242D67;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
  color: #FFFFFF;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  border-radius: 0;
}

.popup-content {
    width: auto;
    max-width:45%;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
}

.pop-button {
    background: #242D67;
    color: #FFFFFF;
    font-family: 'Montserrat', sans-serif;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin: 5px;
}

.pop-button:hover {
    background-color: #1e255b;
}

.half-day {
  margin-right: 5px !important;
  margin-top:20px !important;
}

.upload-input {
  display: flex !important;
  align-items:center !important;
  width:103% !important;
  border-radius:7px !important;
  height:55px !important;
  margin-bottom:20px !important;
}
