/* Styles for the overall user profile container */
.user-profile-container {
  padding: 20px;
}


/* Styles for the header section containing the title and edit button */
.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* Styles for the details section within the user profile */
.profile-details {
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-left: 0;
  width:auto !important;
}
.profile-details strong {
  color:#344054;
}
.profile-details input[type="text"] {
  color: #667085;
  }
/* Styles for the two columns */
.column {
  flex: 1;
}

/* Styles for the edit button */
.edit-profile-button {
  background-color: #222d64;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  top: 60%;
  left: 200%;
}

/* Hover effect for the edit button */
.edit-profile-button:hover {
  background-color: #493971;
}
.textbox {
  background: #fffff;
  width: 90% !important;
  height: 50px;
  margin-left:25px;
  border-radius: 8px;
}
.profile-details .column div {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.profile-details .column div strong {
  text-align: left;
  padding-left:25px;
}

.profile-username{
  position: fixed;
  top: 20px;
  right: 239px;
  margin-top: 23px;
}

.profile-icons{
  color: #493971;
  font-size: 20px;
}

.icons-text {
  position: relative;
  right: 23px;
}

.edit-profile-button1 {
  background-color: #222d64;
  color: white;
  border: none;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  top: -103%;
  right: -101%;
}

/* Hover effect for the edit button */
.edit-profile-button:hover {
  background-color: #493971;
}

.profile-page-image {
  width: 75px;
  height: 75px;
  padding : 0;
  border-radius: 200px 200px 200px 200px;
  opacity: 1px;
  margin: 12px;
  position: absolute;
  left: 1%;
  top: 2%;

}

.profilepage-edit-button{
  background-color: #222d64;
  color: white;
  font-size: 16px;
  width:80px;
  height:10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*@media screen and (min-width: 1500px) and (max-width: 1800px) {
    .user-profile-container {
        width: 140%;
        padding: 20px;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1499px) {
    .user-profile-container {
        width: 106% !important;
        padding: 20px;
    }
}*/
.icons-text.profile-info {
    margin-left: 30px;
}

.icons-text.phone-number {
    margin-left: 35px;
}

.profile-container {
  display: flex;
  align-items: center;
  width: 89%;
  height: 104px;
  border: 1px solid #EAECF0;
  border-top: 1px solid #EAECF0;
  background: #F0F6FF;
  position: absolute;
  left: 5%;
  top: 15px;
}
.vertical-line {
  width: 1px;
  height: 80px;
  background-color: #c7c3c3;
  bottom: 10px;
  border: 1px #00000066;
  position: absolute;
  top: 11%;
  left: 30%;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  right: 2%;
  top: 30%;
  position: absolute;
}

.icon-container{
  width: 30vh;
  height: 15vh;
  gap: 0px;
  opacity: 1px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
  text-align: left;
  top: 11%;
  left: 31%;
  position: absolute;
}

.user-profile-h3 {
  padding-top: 65px;
  color: #344054;
  font-family: Inter;
  font-size: 16px;
}

.user-profile-p {
  color: #667085;
  font-family: Inter;
  font-size: 14px;
  white-space: nowrap;
}
.edit-icon {
  position: absolute;
  top: 3vh;
  left: 5vw;
  background-color: #fff;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  transform: translate(-50%, -50%);
}

.profile-container {
  display: flex;
  align-items: center;
}

.user-profile-h3,
.user-profile-p {
  margin-left: 120px;
  margin-top: 1px;
}

.profile-address-section strong {
  text-align:left important;
}

.profile-address-section {
  border-radius: 20px ;
  box-shadow: 2px 3px 4px 1px #00000033;
  margin-top:20px;
  margin-bottom:20px;
  padding-bottom:20px;
  padding-top:20px;
  margin-right: 40px;
}

.profile-address-sections {
  border-radius: 20px ;
  box-shadow: 2px 3px 4px 1px #00000033;
  margin-bottom:20px;
  padding-bottom:20px;
  padding-top:20px;
  margin-right: 40px;
}

.profile-margin {
  margin-top:8rem;
}

@media screen and (max-width: 1310px) {
  .profile-details {
    font-size: 13px;
  }

  .user-profile-h3,
  .user-profile-p {
    margin-left: 100px;
    margin-top: 1px;
  }

  .profile-page-image{
    right:2% important;
  }

  .edit-icon {
    left: 7vw;
  }
}

@media screen and (max-width: 1020px) {
  .icon-container{
    left:35%;
  }

  .vertical-line{
    left:33%;
  }

  profile-details {
    margin-top:0rem;
  }
}

.address-margin {
  margin-top:-1.5rem;
}