.right-content-inactive-user {
  padding: 34px;
}

.inactiveuser-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
}

.inactive-user-table{
  width: 100%;
  min-width: 5%;
  border-collapse: separate;
  z-index: 0;
  border-radius: 15px;
  border-spacing: 0;
  overflow: hidden;
}

.user-profile-photo-inactive-user {
  border-radius: 50%;
  width: 50px;
  height: 45px;
  margin-left: auto;
  margin-right: auto;
}

.pagination-inactive-user {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 30px;
  width:100%;
}

.pagination-inactive-user li a {
  color: #667085;
  text-decoration: none;
  cursor: pointer;
  font-family: Inter, sans-serif;
  font-size: 14px;
}

.pagination-inactive-user li.active a {
  background-color: #F9F5FF;
  border-radius: 5px;
  padding: 12px 16px;
}

.pagination-inactive-user > li:first-child {
  margin-right: auto;
}

.pagination-inactive-user > li:last-child {
  margin-left: auto;
}

.pagination-inactive-user > li:not(:first-child):not(:last-child) {
  display: inline-block;
  margin: 0 10px;
}

.disabled-button-inactive-user {
  pointer-events: none;
  opacity: 0.5;
}