.full-width-container {
    width: 100%;
    overflow: hidden;
}

.at-gems {
    width: 100%;
    background: #242D67;
    text-align: center;
    padding: 1rem 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: #FFFFFF;
    font-size: 1.5rem;
}

.password-container {
    background-image: url('/assets/images/password.jpg');
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-size: cover;
    background-position: center;
    padding: 0 1rem;
    width: 100%;
}

.password-form-container {
    top: 60vh;
    width: 100%;
    border: 1px solid white;
    background-color: #FFFFFF;
    box-shadow: 4px 7px 21px 1px #00000033;
    border-radius: 20px;
    padding: 3rem;
    max-width: 500px;
    margin-top: -50px;
}

.setpassword-form-container {
    top: 60vh;
    width: 100%;
    border: 1px solid white;
    background-color: #FFFFFF;
    box-shadow: 4px 7px 21px 1px #00000033;
    border-radius: 20px;
    padding: 3rem;
    max-width: 500px;
    margin-top: -100px;
}

.forgot-password {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: #F90716;
}

.email-label, .password-label {
    margin-top: -4px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: #000000;
}

.password-input, .email-input {
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    color: #000000;
    opacity: 0.5;
    margin: 0.5rem 0;
    padding: 1rem;
    border-radius: 0;
    box-shadow: 0px 3px 10.5px 0px #00000026;
}

.password-input, .email-input::placeholder {
    color: rgba(0, 0, 0, 0.5);
    opacity: 1;
}

.password-input, .email-input:focus,
.password-input, .email-input:not(:placeholder-shown) {
    color: #000000;
    font-size: 12px;
    opacity: 1;
}

.submit-button {
    width: 100%;
    background: #242D67;
    padding: 15px;
    border-radius: 10px;
    cursor: pointer;
    color: #FFFFFF;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    border-radius: 0;
}

.submit-button:hover {
    background-color: #1e255b;
}

.message {
    padding: 10px;
    margin-top: 20px;
    font-size: 1em;
}

.greeting {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #000000;
}

.password-toggle-icon {
    position: relative;
    top: -25%;
    transform: translateY(-50%);
    right: -89%;
    cursor: pointer;
    color: #6c757d;
}

.password-toggle-icon {
    margin-left: -3px;
    position: relative;
    top: -44px;
    transform: translateY(-50%);
    cursor: pointer;
    color: #6c757d;
}

.confirm-password-toggle-icon {
    margin-left: 354px;
    position: relative;
    top: -44px;
    transform: translateY(-50%);
    cursor: pointer;
    color: #6c757d;
}

@media screen and (max-width: 1310px) {
  .setpassword-form-container, .password-form-container {
     margin-top: 20px;
     margin-bottom:20px;
  }
}