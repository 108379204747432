.right-content-active-emp {
  padding: 34px;
}

.userpage-container-active-emp {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
}

.allusers-table-active-emp {
  width: 100%;
  min-width: 5%;
  border-collapse: separate;
  z-index: 0;
  border-radius: 15px;
  border-spacing: 0;
  overflow: hidden;
}

.allusers-table-active-emp th:nth-child(1),
.allusers-table-active-emp td:nth-child(1) {
  width: 8%;
}

.allusers-table-active-emp th:nth-child(2),
.allusers-table-active-emp td:nth-child(2) {
  width: 6%;
}

.allusers-table-active-emp th:nth-child(3),
.allusers-table-active-emp td:nth-child(3) {
  width: 15%;
}

.allusers-table-active-emp th:nth-child(4),
.allusers-table-active-emp td:nth-child(4) {
  width: 15%;
}

.allusers-table-active-emp th:nth-child(5),
.allusers-table-active-emp td:nth-child(5) {
  width: 15%;
}

.allusers-table-active-emp th:nth-child(6),
.allusers-table-active-emp td:nth-child(6) {
  width: 15%;
}

.allusers-table-active-emp th:nth-child(7),
.allusers-table-active-emp td:nth-child(7) {
  padding-right: 40px;
}

.edit-button-active-emp{
  background-color: #222d64;
  color: white;
  padding: 8px 43px;
  font-size: 16px;
  border: 2px solid;
  border-radius: 18px;
}

.user-profile-photo-active-emp {
  border-radius: 50%;
  width: 50px;
  height: 45px;
  margin-left: auto;
  margin-right: auto;
}

.pagination-active-emp{
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 30px;
  width:100%;
}

.pagination-active-emp li a {
  color: #667085;
  text-decoration: none;
  cursor: pointer;
  font-family: Inter, sans-serif;
  font-size: 14px;
}

.pagination-active-emp li.active a {
  background-color: #F9F5FF;
  border-radius: 5px;
  padding: 12px 16px;
}

.pagination-active-emp > li:first-child {
  margin-right: auto;
}

.pagination-active-emp > li:last-child {
  margin-left: auto;
}

.pagination-active-emp > li:not(:first-child):not(:last-child) {
  display: inline-block;
  margin: 0 10px;
}

.disabled-button-active-emp {
  pointer-events: none;
  opacity: 0.5;
}

.search-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0px;
  margin-top: 15px;
  margin-bottom: 0px !important;
  margin-left: 15px;
  margin-right: 0;
}

.search-container input,
.search-container select,
.search-container button {
  margin: 0;
  padding: 5px;
}

.search-container input,
.search-container select {
  background-color: #F7FAFF;
}

.search-container input{
  border: 1px solid #ccc;
  padding: 4px 8px;
  border-radius: 8px;
  margin-right:10px;
}

.search-container select {
  background-image: url('/assets/images/downarrow.png');
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) center;
  appearance: none;
  border: 1px solid #ccc;
  padding: 4px 8px;
  border-radius: 8px;
  width: 200px;
}

.search-container button {
  background-color: #242D67;
  margin-top:0px !important;
  padding: 1rem;
  background-image: url('/assets/images/search-icon.png');
  background-size: 22px 22px;
  background-repeat: no-repeat;
  background-position: center;
  width : 62px;
}